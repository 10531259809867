<template>
  <div class="galleryMainContainer">
    <media-list />
  </div>
</template>

<script>
import mediaList from "../components/gallery/mediaList.vue";

export default {
  components: { mediaList },
};
</script>

<style>
</style>